import axios from 'axios';
import { inspect } from 'util';

function logToHttpServer(...args) {
	// const e = new Error();
	// const regex = /\((.*):(\d+):(\d+)\)$/;
	// const match = regex.exec(e.stack.split('\n')[2]);
	// args = [
	// 	match?.length > 2 ? match[2] : undefined,
	// 	match?.length > 1 ? match[1] : undefined,
	// 	...args,
	// ];
	const banLog = true;
	if (!banLog) {
		axios.post('/api/logserver/log', inspect(args, false, 3));
		if (args.includes('error')) {
			console.error(...args);
		} else {
			console.info(...args);
		}
	}
}

export { logToHttpServer };
