import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ProTip from './ProTip';
import MainContent from './Body';

import { teal } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import MainPage from './MainPage';
import AudioPageRaw from './audio/AudioRaw';
import { viewPageStat } from '../Common/Utils/StatisticsService';

const theme = createTheme({
  palette: {
    primary: teal,
    mode: 'light',
  },
});


function RoutesWrapper() {
  const location = useLocation();
  useEffect(() => {
    viewPageStat(location.pathname)
  }, [location])

  return <>
    <Routes>
      {/* <Route path="/list/:path" element={<List />} /> */}
      {/* <Route path="/play/:path" element={<Player />} /> */}
      <Route path="/" element={<MainPage />} />
      <Route path="audio" element={<AudioPageRaw />} />
    </Routes>
  </>
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <RoutesWrapper />
      </Router>
    </ThemeProvider>
  );
}

export default App;
