import { createContext } from 'react';
import { AudioCategory } from '../service/Audio.service';

class Audio {
	id: string;
	name: string;
	url: string;
	audioUrl: string;
	Category: string;
	Source: string;
	trackId: string;
	createdAt: string;
	updatedAt: string;
	deletedAt: string;
	viewed: boolean;
	favorite: boolean;

	public constructor(
		id: string,
		name: string = '',
		url: string = '',
		audioUrl: string = '',
		Category: string = '',
		Source: string = '',
		trackId: string = '',
		createdAt: string = '',
		updatedAt: string = '',
		deletedAt: string = '',
		viewed: boolean = false,
		favorite: boolean = false
	) {
		this.id = id;
		this.name = name;
		this.Category = Category;
		this.url = url;
		this.audioUrl = audioUrl;
		this.Source = Source;
		this.trackId = trackId;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.deletedAt = deletedAt;
		this.viewed = viewed;
		this.favorite = favorite;
	}
}
enum AudioActionType {
	add = 'add',
	addCategory = 'addCategory',
	setCurrentCategory = 'setCurrentCategory',
	setCategoryIndex = 'setCategoryIndex',
	stepCategoryIndex = 'stepCategoryIndex',
	change = 'change',
	delete = 'delete',
	count = 'count',
}
let actionCount = 0;

class AudioStore {
	audios: Audio[];
	categoriedAudios: Record<AudioCategory, Audio[]>;
	// these ui state better put into components useState, here maybe not a good idea
	currentSelectedTab: AudioCategory;
	categortyCurrentAudioIndex: Record<AudioCategory, number>;
	id: string;
	constructor(
		audios: Audio[] = [],
		categoriedAudios: Record<AudioCategory, Audio[]> = {
			[AudioCategory.BaDianYiKe]: [],
			[AudioCategory.KuaiXunBuLianBo]: [],
			[AudioCategory.All]: [],
			[AudioCategory.KeJinDaShiJian]: [],
			[AudioCategory.XinShangeYeGuanCha]: [],
			[AudioCategory.ShangYeQingBaoJu]: [],
			[AudioCategory.ZhangQiShangYeSiWei]: [],
			[AudioCategory.TianFangYeTan]: [],
			[AudioCategory.JiMuGuanZhu]: [],
			[AudioCategory.ShiFenZhongXinWenZaoCan]: [],
			[AudioCategory.HuanQiu16Zhang]: [],
			[AudioCategory.XinWenLianBo]: [],
			[AudioCategory.XueQiueCaiJingYouShengDu]: [],
			[AudioCategory.HuXiuShangYeYouWeiDao]: [],
			[AudioCategory.TangShiSongCiYuanQu]: [],
		},
		currentSelectedTab: AudioCategory = AudioCategory.BaDianYiKe,
		categortyCurrentAudioIndex: Record<AudioCategory, number> = {
			[AudioCategory.BaDianYiKe]: 0,
			[AudioCategory.KuaiXunBuLianBo]: 0,
			[AudioCategory.All]: 0,
			[AudioCategory.KeJinDaShiJian]: 0,
			[AudioCategory.XinShangeYeGuanCha]: 0,
			[AudioCategory.ShangYeQingBaoJu]: 0,
			[AudioCategory.ZhangQiShangYeSiWei]: 0,
			[AudioCategory.TianFangYeTan]: 0,
			[AudioCategory.JiMuGuanZhu]: 0,
			[AudioCategory.ShiFenZhongXinWenZaoCan]: 0,
			[AudioCategory.HuanQiu16Zhang]: 0,
			[AudioCategory.XinWenLianBo]: 0,
			[AudioCategory.XueQiueCaiJingYouShengDu]: 0,
			[AudioCategory.HuXiuShangYeYouWeiDao]: 0,
			[AudioCategory.TangShiSongCiYuanQu]: 0,
		}
	) {
		this.audios = audios;
		this.categoriedAudios = categoriedAudios;
		this.currentSelectedTab = currentSelectedTab;
		this.categortyCurrentAudioIndex = categortyCurrentAudioIndex;
	}
}

class AudioAction {
	id: string;
	type: AudioActionType;
	audio: Audio;
	category: AudioCategory;
	audios: Audio[];
	index: number;
	constructor(
		id: string,
		type: AudioActionType,
		audio: Audio,
		category: AudioCategory = AudioCategory.BaDianYiKe,
		audios: Audio[] = [],
		index: number = 0
	) {
		this.id = id;
		this.type = type;
		this.audio = audio;
		this.audios = audios;
		this.category = category;
		this.index = index;
	}
}

class HasIdAction extends AudioAction {
	constructor(
		type: AudioActionType,
		audio: Audio,
		category: AudioCategory = AudioCategory.BaDianYiKe,
		audios: Audio[] = [],
		index: number = 0
	) {
		super(`${actionCount++}`, type, audio, category, audios, index);
	}
}

class AddAudioAction extends HasIdAction {
	constructor(id: string, audio: Audio) {
		super(AudioActionType.add, audio);
	}
}
class ChangeAudioAction extends HasIdAction {
	constructor(id: string, audio: Audio) {
		super(AudioActionType.change, audio);
	}
}
class DeleteAudioAction extends HasIdAction {
	constructor(id: string, audio: Audio) {
		super(AudioActionType.delete, audio);
	}
}
class SetStoreIdAction extends HasIdAction {
	constructor(id: string) {
		super(AudioActionType.count, undefined);
	}
}
class AddCategoryAudiosAction extends HasIdAction {
	constructor(categroy: AudioCategory, audios: Audio[]) {
		super(AudioActionType.addCategory, undefined, categroy, audios);
	}
}
class SetCurrentAudioCategoryAction extends HasIdAction {
	constructor(categroy: AudioCategory) {
		super(AudioActionType.setCurrentCategory, undefined, categroy);
	}
}
class SetCategoryCurrentIndexAction extends HasIdAction {
	constructor(categroy: AudioCategory, index: number) {
		super(
			AudioActionType.setCategoryIndex,
			undefined,
			categroy,
			undefined,
			index
		);
	}
}
class StepCategoryCurrentIndexAction extends HasIdAction {
	constructor(categroy: AudioCategory, index: number) {
		super(
			AudioActionType.stepCategoryIndex,
			undefined,
			categroy,
			undefined,
			index
		);
	}
}

function audioStoreReducer(
	audioStore: AudioStore,
	action: AudioAction
): AudioStore {
	switch (action.type) {
		case AudioActionType.add: {
			return { ...audioStore, audios: [...audioStore.audios, action.audio] };
		}
		case AudioActionType.setCurrentCategory: {
			return { ...audioStore, currentSelectedTab: action.category };
		}
		case AudioActionType.setCategoryIndex: {
			return {
				...audioStore,
				categortyCurrentAudioIndex: {
					...audioStore.categortyCurrentAudioIndex,
					[action.category]: action.index,
				},
			};
		}
		case AudioActionType.stepCategoryIndex: {
			return {
				...audioStore,
				categortyCurrentAudioIndex: {
					...audioStore.categortyCurrentAudioIndex,
					[action.category]:
						audioStore.categortyCurrentAudioIndex[action.category] +
						action.index,
				},
			};
		}
		case AudioActionType.count: {
			return { ...audioStore, id: action.id };
		}
		case AudioActionType.addCategory: {
			let newCategoryAudios = {
				...audioStore.categoriedAudios,
			};
			if (newCategoryAudios[action.category]) {
				const newAudios = newCategoryAudios[action.category].concat(
					action.audios
				);
				newCategoryAudios[action.category] = newAudios;
			} else {
				newCategoryAudios[action.category] = action.audios;
			}
			return {
				...audioStore,
				categoriedAudios: newCategoryAudios,
				audios: [...audioStore.audios, ...action.audios],
			};
		}
		case AudioActionType.change: {
			return {
				...audioStore,
				audios: audioStore.audios.map((t) => {
					if (t.id === action.audio.id) {
						return action.audio;
					} else {
						return t;
					}
				}),
			};
		}
		case AudioActionType.delete: {
			return {
				...audioStore,
				audios: audioStore.audios.filter((t) => t.id !== action.audio.id),
			};
		}
		default: {
			throw Error('Unknown action: ' + action.type);
		}
	}
}

const AudioStoreContext = createContext(null);
const AudioStoreDispatchContext = createContext(null);
const initialAudioStore = new AudioStore();

export {
	Audio,
	AudioStore,
	audioStoreReducer,
	AddAudioAction,
	DeleteAudioAction,
	ChangeAudioAction,
	AudioStoreContext,
	AudioStoreDispatchContext,
	initialAudioStore,
	SetStoreIdAction,
	AddCategoryAudiosAction,
	SetCurrentAudioCategoryAction,
	SetCategoryCurrentIndexAction,
	StepCategoryCurrentIndexAction,
};
