import React from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import bigMoneyCat from './assets/BigMoneyCat.jpg';

import { Button, CardActions, CardContent, CardMedia } from '@mui/material';

const style = {
  card: {
    display: 'inline-block',
    width: '30%',
    height: '180px',
    marginRight: '50px',
    // "box-sizing": "border-box",
    padding: '10px auto',
  },

  wechatTip: {
    padding: 0,
  },
};

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
    •
  </Box>
);
const card = (
  <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        <Link href="https://blog.csdn.net/junbujianwpl">CSDN Blog</Link>
      </Typography>
      <Typography variant="h5" component="div" color="#000">
        <Link href="https://github.com/junbujianwpl">github</Link>
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        <Link href="https://stackoverflow.com/users/7720976/w-perrin">
          stack overflow
        </Link>
      </Typography>
    </CardContent>
  </React.Fragment>
);

const wechatCard = (
  <React.Fragment>
    <CardContent sx={{ pb: 0, pt: '4px' }}>
      <Typography
        sx={{ fontSize: 14, py: 0 }}
        variant="h5"
        alignSelf="top"
        color="text.secondary"
        style={style.wechatTip}
        gutterBottom>
        公众号:bigmoneycat
      </Typography>
    </CardContent>
    <CardMedia
      component="img"
      height="140"
      image={bigMoneyCat}
      alt="green iguana"
    />
  </React.Fragment>
);

function MainContent() {
  return (
    <>
      {[card, wechatCard].map((x) => (
        <Card variant="outlined" style={style.card}>
          {x}
        </Card>
      ))}
    </>
  );
}

export default MainContent;
