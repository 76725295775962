import React, {
    useCallback,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';
import '../App.css';


import './Audio.css';
import Box from '@mui/material/Box';
import axios from 'axios';
import {
    AudioCategory,
    audioCatoryToTitle,
    audioService,
} from './service/Audio.service';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

// Most of react-virtualized's styles are functional (eg position, size).
// Functional styles are applied directly to DOM elements.
// The Table component ships with a few presentational styles as well.
// They are optional, but if you want them you will need to also import the CSS file.
// This only needs to be done once; probably during your application's bootstrapping process.
import 'react-virtualized/styles.css';

// But if you only use a few react-virtualized components,
// And you're concerned about increasing your application's bundle size,
// You can directly import only the components you need, like so:
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import {
    AddCategoryAudiosAction,
    AudioStoreContext,
    AudioStoreDispatchContext,
    SetStoreIdAction,
    audioStoreReducer,
    initialAudioStore,
    Audio,
    SetCurrentAudioCategoryAction,
    StepCategoryCurrentIndexAction,
    SetCategoryCurrentIndexAction,
} from './store/Audio.store';
import { logToHttpServer } from '../../Common/Utils/LogService';

if (process.env.NODE_ENV == 'development' && !process.env.REACT_APP_BUILD_DEV) {
    axios.defaults.baseURL = 'http://localhost:3000';
}

logToHttpServer('version', process.env.REACT_APP_VERSION)
console.error('version', process.env.REACT_APP_VERSION);


function AudioVirtualList({
    /** Are there more items to load? (This information comes from the most recent API request.) */
    hasNextPage,
    /** Are we currently loading a page of items? (This may be an in-flight flag in your Redux store for example.) */
    isNextPageLoading,
    /** List of items loaded so far */
    list,
    /** Callback function (eg. Redux action-creator) responsible for loading the next page of items */
    loadNextPage,
    /** current play index */
    currentPlayIndex,
    /** play dest audio*/
    setPlayIndex,
}) {
    const matches = useMediaQuery('(min-width:800px)');
    const listRef = useRef(null);
    function scrollToItem(index) {
        listRef.current.scrollToRow(index); // second parameter here could be 'auto', 'smart', 'center', 'end', 'start'
    }
    useEffect(() => {
        scrollToItem(currentPlayIndex);
    }, [currentPlayIndex]);

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const rowCount = hasNextPage ? list?.size ?? 0 + 1 : list?.size ?? 0;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreRows = isNextPageLoading ? () => { } : loadNextPage;

    // Every row is loaded except for our loading indicator row.
    const isRowLoaded = ({ index }) => {
        return !hasNextPage || index < list.length;
    };

    // Render a list item or a loading indicator.
    const rowRenderer = ({ index, key, style }) => {
        let content;

        if (!isRowLoaded({ index })) {
            content = 'Loading...';
        } else {
            content = (
                <div
                    className={
                        (currentPlayIndex == index ? 'playing' : '') + ' audioItem'
                    }>
                    <span>{index + ' ' + list?.[index].name}</span>
                    <button
                        className="playButton"
                        onClick={() => {
                            setPlayIndex(index);
                        }}>
                        播放
                    </button>
                </div>
            );
        }

        return (
            <div key={key} style={style}>
                {content}
            </div>
        );
    };

    return (
        <div className="virtualList">
            <AutoSizer>
                {({ height, width }) => (
                    <InfiniteLoader
                        isRowLoaded={isRowLoaded}
                        loadMoreRows={loadMoreRows}
                        rowCount={rowCount}>
                        {({ onRowsRendered, registerChild }) => (
                            <List
                                ref={listRef}
                                onRowsRendered={onRowsRendered}
                                rowRenderer={rowRenderer}
                                height={height}
                                width={width}
                                rowHeight={matches ? 40 : 80}
                                rowCount={list.length}
                            />
                        )}
                    </InfiniteLoader>
                )}
            </AutoSizer>
        </div>
    );
}


function useRefCallback() {
    const [ready, setReady] = useState(false);
    const refCallback = useCallback(node => {
        if (node) {
            setReady(true)
        }
    }, []);

    return [ready, refCallback];
}


let requestKeys = new Set<string>();
function AudioPageRaw() {
    const [audioStore, dispatchAudioStore] = useReducer(
        audioStoreReducer,
        initialAudioStore
    );
    const audioRef = useRef<HTMLAudioElement>(null)
    const playNextCallbackRef = useRef(null)
    const [curTabAudios, setCurTabAudios] = useState(null);
    const [currentAudio, setCurrentAudio] = useState(null);
    const [eventHandled, setEventHandled] = useState(null);
    const playTimeRef = useRef(null);
    function setTabIndex(key: AudioCategory) {
        dispatchAudioStore(new SetCurrentAudioCategoryAction(key));
    }
    const playPrevNext = useCallback(
        (next: boolean = false) => {
            dispatchAudioStore(
                new StepCategoryCurrentIndexAction(
                    audioStore.currentSelectedTab,
                    next ? 1 : -1
                )
            );
        },
        [audioStore.currentSelectedTab]
    );
    async function fetchAudiosAndUpdate(
        category: AudioCategory,
        page: number,
        perPage: number
    ) {
        const reqKey = `${category}-${page}-${perPage}`;
        try {
            if (requestKeys.has(reqKey)) {
                return;
            }
            requestKeys.add(reqKey);
            const response = await audioService.getAudio(page, perPage, category);
            logToHttpServer(response);
            const list = response?.data?.filter((x) => !!x.audioUrl);
            dispatchAudioStore(new AddCategoryAudiosAction(category, list));
        } catch (error) {
            logToHttpServer('fetch data error', error);
            requestKeys.delete(reqKey);
        }
    }
    useEffect(() => {
        fetchAudiosAndUpdate(AudioCategory.BaDianYiKe, 0, 10).then(() => {
            dispatchAudioStore(
                new SetCategoryCurrentIndexAction(AudioCategory.BaDianYiKe, 0)
            );

            // prefetch other categories to avoid shrink when switch tab
            Object.values(AudioCategory).forEach((k) => {
                if (![AudioCategory.All, AudioCategory.BaDianYiKe].includes(k)) {
                    fetchAudiosAndUpdate(k, 0, 5);
                }
            });

        });
    }, []);
    useEffect(() => {
        setInterval(() => {
            if (!audioRef.current) {
                return
            }
            const au = audioRef.current
            console.log("play progress", au.buffered, au.currentTime, au.paused)
            if (au.currentTime == playTimeRef.current && !au.paused) {
                au.load()
            } else {
                playTimeRef.current = au.currentTime
            }
        }, 1000)
    }, [])
    useEffect(() => {
        const curTab = audioStore.currentSelectedTab;
        const idx = audioStore.categortyCurrentAudioIndex[curTab];
        let audios = audioStore.categoriedAudios[curTab];
        if (curTab === AudioCategory.All) {
            audios = [
                ...new Set([].concat(...Object.values(audioStore.categoriedAudios))),
            ].sort((a: Audio, b: Audio) => {
                return +b.trackId - +a.trackId;
            });
        }

        // set current audio
        if (audios?.length >= idx) {
            setCurrentAudio(audios[idx]);
        }

        // fetch new audios if near to end
        if (idx + 5 > audios?.length) {
            fetchAudiosAndUpdate(
                curTab,
                audios.length == 0 ? 0 : 1,
                audios.length == 0 ? 10 : audios.length
            );
        }

        // set cur tab audios
        setCurTabAudios(audios);
    }, [
        audioStore.categoriedAudios,
        audioStore.currentSelectedTab,
        audioStore.categortyCurrentAudioIndex,
    ]);
    const playAudio = useCallback(
        (idx) => {
            dispatchAudioStore(
                new SetCategoryCurrentIndexAction(audioStore.currentSelectedTab, idx)
            );
        },
        [audioStore.currentSelectedTab]
    );

    useEffect(() => {
        playNextCallbackRef.current = playPrevNext
        if (!audioRef.current || eventHandled) {
            return;
        }

        audioRef.current?.addEventListener("error", (err) => {
            logToHttpServer("audio error", err)
        })
        audioRef.current?.addEventListener("progress", (pct) => {
            logToHttpServer("audio progress", pct)
        })
        audioRef.current?.addEventListener("playing", (pct) => {
            logToHttpServer("audio playing", pct)
        })
        audioRef.current?.addEventListener("ended", () => {
            logToHttpServer("audio end")
            playNextCallbackRef.current?.(true)
        })
        setEventHandled(true)
    }, [audioRef, eventHandled, currentAudio, playPrevNext])

    return (
        <AudioStoreContext.Provider value={audioStore}>
            <AudioStoreDispatchContext.Provider value={dispatchAudioStore}>
                <div className="AudioPage">
                    <div>
                        <button
                            className="prev"
                            onClick={() => {
                                playPrevNext(false);
                            }}>
                            上一篇
                        </button>
                        <button
                            className="next"
                            onClick={() => {
                                playPrevNext(true);
                            }}>
                            下一篇
                        </button>
                    </div>
                    {currentAudio && <audio src={currentAudio?.audioUrl} ref={audioRef} controls autoPlay={true} />}
                    <TabContext value={audioStore.currentSelectedTab}>
                        <Box
                            sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                            <TabList
                                variant="scrollable"
                                orientation="horizontal"
                                scrollButtons={true}
                                allowScrollButtonsMobile
                                onChange={(idx) => { }}
                                aria-label="lab API tabs example">
                                {Array.from(audioCatoryToTitle.keys()).map((key) => {
                                    return (
                                        <Tab
                                            label={audioCatoryToTitle.get(key)}
                                            key={key}
                                            value={key}
                                            onClick={() => setTabIndex(key)}
                                        />
                                    );
                                })}
                            </TabList>
                        </Box>
                        {Array.from(audioCatoryToTitle.keys()).map((key) => {
                            return <TabPanel key={key} value={key}></TabPanel>;
                        })}
                    </TabContext>
                    {curTabAudios ? (
                        <Box sx={{ width: '98%', height: '80%' }}>
                            <AudioVirtualList
                                hasNextPage={true}
                                isNextPageLoading={true}
                                list={curTabAudios}
                                loadNextPage={() => {
                                    logToHttpServer('DDDDDDDDDDDDDDDDD load next page');
                                }}
                                currentPlayIndex={
                                    audioStore.categortyCurrentAudioIndex[
                                    audioStore.currentSelectedTab
                                    ]
                                }
                                setPlayIndex={(idx) => {
                                    playAudio(idx);
                                }}
                            />
                        </Box>
                    ) : undefined}
                </div>
            </AudioStoreDispatchContext.Provider>
        </AudioStoreContext.Provider>
    );
}

export default AudioPageRaw;
