import axios from 'axios';
enum AudioCategory {
	BaDianYiKe = '8DianYiKe',
	KuaiXunBuLianBo = 'KuaiXunBuLianBo',
	KeJinDaShiJian = 'KeJinDaShiJian',
	XinShangeYeGuanCha = 'XinShangYeGuanCha',
	ShangYeQingBaoJu = 'ShangYeQingBaoJu',
	ZhangQiShangYeSiWei = 'ZhangQiShangYeSiWei',

	TianFangYeTan = 'TianFangYeTan',

	JiMuGuanZhu = 'JiMuGuanZhu', //极目关注
	ShiFenZhongXinWenZaoCan = '10FenZhongXinWenZaoCan', //10分钟新闻早餐
	HuanQiu16Zhang = 'HuanQiu16Zhang', //环球十六章  free
	XinWenLianBo = 'XinWenLianBo', //新闻联播  fre
	XueQiueCaiJingYouShengDu = 'XueQiueCaiJingYouShengDu', //雪球·财经有深度  free
	HuXiuShangYeYouWeiDao = 'HuXiuShangYeYouWeiDao', //虎嗅·商业有味道  free

	TangShiSongCiYuanQu = 'TangShiSongCiYuanQu',

	All = 'all',
}

class AudioService {
	async getAudio(
		page = -1,
		perPage = -1,
		category: AudioCategory = AudioCategory.All
	): Promise<any> {
		try {
			const res = axios.get('/api/audio/getall', {
				params: { page, perpage: perPage, category },
			});
			return res;
		} catch (error) {
			console.error(error);
			throw error;
		}
	}
}

const audioCatoryToTitle = new Map([
	[AudioCategory.All, '全部'],
	[AudioCategory.BaDianYiKe, '8点一刻'],
	[AudioCategory.KuaiXunBuLianBo, '快迅不联播'],
	[AudioCategory.KeJinDaShiJian, '氪金大事件'],
	[AudioCategory.XinShangeYeGuanCha, '新商业观察'],
	[AudioCategory.ShangYeQingBaoJu, '商业情报局'],
	[AudioCategory.ZhangQiShangYeSiWei, '张绮商业思维'],

	[AudioCategory.TianFangYeTan, '天方烨谈'],

	[AudioCategory.JiMuGuanZhu, '极目关注'], //极目关注
	[AudioCategory.ShiFenZhongXinWenZaoCan, '10分钟新闻早餐'], //10分钟新闻早餐
	[AudioCategory.HuanQiu16Zhang, '环球十六章'], //环球十六章  free
	[AudioCategory.XinWenLianBo, '新闻联播'], //新闻联播  fre
	[AudioCategory.XueQiueCaiJingYouShengDu, '雪球·财经有深度'], //雪球·财经有深度  free
	[AudioCategory.HuXiuShangYeYouWeiDao, '虎嗅·商业有味道'], //虎嗅·商业有味道  free
	[AudioCategory.TangShiSongCiYuanQu, '诗词曲'],
]);

const audioService = new AudioService();

export { audioService, audioCatoryToTitle, AudioCategory };
