import React from 'react';
import logo from './logo.svg';
import './App.css';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ProTip from './ProTip';
import MainContent from './Body';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://allenwu.club/">
        www.allenwu.club
      </Link>{' '}
      {new Date().getFullYear()}
      {'. '}
      <Link href="https://beian.miit.gov.cn/">京ICP备2022020885号</Link>
    </Typography>
  );
}

function MainPage() {
  return (
    <div className="App">
      <Container maxWidth="sm">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            晴天的随笔
          </Typography>
          <MainContent></MainContent>
          <ProTip />
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}

export default MainPage;
