import axios from 'axios';
import { inspect } from 'util';
import { logToHttpServer } from './LogService';

function viewPageStat(...args) {
	axios
		.post(`/api/statistic/viewpage?page=${args?.[0]}`)
		.catch((err) => {
			logToHttpServer('viewpage stat failed', err);
		});
}

export { viewPageStat };
